import { render, staticRenderFns } from "./TypeResidencyStatus.vue?vue&type=template&id=158db569&scoped=true&"
import script from "./TypeResidencyStatus.ts?vue&type=script&lang=ts&"
export * from "./TypeResidencyStatus.ts?vue&type=script&lang=ts&"
import style0 from "./TypeResidencyStatus.vue?vue&type=style&index=0&id=158db569&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158db569",
  null
  
)

export default component.exports